import { UnlikelyVariant } from '@unlikelystudio/commerce-connector'

import { ProductHeaderVariantsProps } from '~/components/UI/ProductHeader/ProductHeaderVariants'

export function getSelectedOptionsGroupedByType(
  variants: Pick<UnlikelyVariant, 'selectedOptions' | 'availableForSale'>[],
) {
  return variants?.reduce((acc, variant) => {
    variant?.selectedOptions?.forEach((option) => {
      if (variant?.availableForSale) {
        if (!acc[option.name]) {
          acc[option.name] = []
        }

        if (!acc[option.name].includes(option.value)) {
          acc[option.name].push(option.value)
        }
      }
    })
    return acc
  }, {})
}

export function getVariantFromSelectedOptions(
  selectedOptions: UnlikelyVariant['selectedOptions'],
  variants: ProductHeaderVariantsProps['variants'],
) {
  return variants?.find((variant) => {
    return variant?.selectedOptions?.every((option) => {
      return selectedOptions?.some((selectedOption) => {
        return (
          selectedOption?.name === option.name &&
          selectedOption?.value === option.value
        )
      })
    })
  })
}
